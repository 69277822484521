<template>
  <div class="page-filter">
    <!-- 客户类型 -->
    <filter-item
      v-model:value="select.customerType"
      title="客户类型"
      :options="opt.customerType"
    />
    <!-- 客户行为 -->
    <!-- <filter-item
      v-model:value="select.customerBehavior"
      title="客户行为"
      :options="opt.customerBehavior"
    /> -->
    <!-- 标签信息 -->
    <div class="page-filter-label">标签信息</div>
    <template v-for="(item, idx) in opt.label" :key="item.type">
      <filter-item
        v-model:value="select.label[idx]"
        :title="item.type"
        :options="item.list"
      />
    </template>
    <div class="page-filter-footer">
      <van-button>已选人数：{{ userNum === null ? '--' : userNum }}</van-button>
      <van-button
        v-if="!userids"
        type="primary"
        :loading="estimating"
        @click="handleEstimate"
      >
        预估
      </van-button>
      <van-button v-else type="primary" @click="handleSubmit">确定</van-button>
    </div>
  </div>
</template>

<script>
import { Button, Notify, Dialog } from 'vant'
import { getLabel, getCustomerByLabel } from '../../../service/customer.service'
import FilterItem from './_filter-item.vue'

export default {
  components: {
    FilterItem,
    [Button.name]: Button
  },
  data() {
    return {
      // 预估中...
      estimating: false,
      // 预估出的用户
      userids: null,
      // 筛选条件
      select: {
        customerType: [],
        customerBehavior: [],
        label: []
      },
      // 筛选参数
      opt: {
        customerType: [],
        customerBehavior: [],
        label: []
      }
    }
  },
  computed: {
    userNum() {
      if (!this.userids) return null
      return this.userids.length
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 数据初始化
    async initData() {
      const res = await getLabel()
      this.opt.customerType = res.customerType
      this.opt.customerBehavior = res.customerBehavior
      this.opt.label = res.label
      this.select.customerType = []
      this.select.customerBehavior = []
      this.select.label = Array(res.label.length).fill([])
    },
    // 估算
    async handleEstimate() {
      this.estimating = true
      try {
        this.userids = await getCustomerByLabel({
          customerType: this.select.customerType,
          customerBehavior: this.select.customerBehavior,
          label: [].concat(...this.select.label)
        })
      } catch (e) {
        Notify({ type: 'danger', message: '预估数据获取失败：' + e })
      }
      this.estimating = false
    },
    // 确定
    handleSubmit() {
      // 数量进行限制
      if (this.userNum > 200) {
        Dialog.confirm({
          title: '提示',
          message: '人数已超过200人限制，是否取前200个客户？',
          beforeClose: async (action) => {
            if (action !== 'confirm') return true
            this.$emit('submit', this.userids.slice(0, 200))
            return true
          }
        })
      } else {
        this.$emit('submit', this.userids)
      }
    }
  },
  watch: {
    select: {
      handler: function () {
        // 选择条件改变后，清空所选用户
        this.userids = null
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.page-filter {
  padding: 12px 0 44px 0;

  &-label {
    display: inline-block;
    font-weight: bold;
    margin: 0 8px 12px;
    border-bottom: 1px solid @blue;
  }

  &-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    > .van-button {
      flex: 1;
    }
  }
}
</style>
