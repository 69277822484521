<template>
  <div class="filter-item">
    <div class="label">{{ title }}</div>
    <div class="content">
      <div
        v-for="item in options"
        :key="item.id"
        class="tag"
        :class="{ active: isActive(item) }"
        @click="handleClick(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 标签列表
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // 是否选中
    isActive(item) {
      return this.value.includes(item.id)
    },
    // 标签点击
    handleClick(item) {
      const idx = this.value.indexOf(item.id)
      const value = [...this.value]
      if (idx > -1) {
        value.splice(idx, 1)
      } else {
        value.push(item.id)
      }
      this.$emit('update:value', value)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.filter-item {
  padding-bottom: 12px;
  > .label {
    font-size: 14px;
    font-weight: bold;
    padding: 0 8px 8px;
  }
  > .content {
    color: #5c5c5c;
    padding-left: 8px;
    > .tag {
      font-size: 12px;
      display: inline-block;
      text-align: center;
      min-width: 80px;
      height: 26px;
      line-height: 26px;
      margin: 0 8px 8px 0;
      border-radius: 2px;
      padding: 0 8px;
      background: #f5f5f5;
      &.active {
        color: #fff;
        background: #1989fa;
      }
    }
  }
}
</style>
