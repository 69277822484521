<template>
  <div class="page-guide">
    <!-- 群发规则 -->
    <template v-if="!isGroupMode">
      <div class="page-guide-title">
        <van-icon name="label-o" />
        <span>群发规则</span>
      </div>
      <div class="page-guide-body hint">
        <p>客户每个自然日最多能接收来自一名成员的一条群发消息；</p>
        <p>每次群发最多可选200个客户；</p>
        <p>群发时会自带一个网页卡片，可后台配置或自行删除。</p>
      </div>
    </template>
    <div class="page-guide-title">
      <van-icon name="label-o" />
      <span>Step1：复制文案</span>
    </div>
    <!-- 内容 -->
    <div class="page-guide-body container">
      <div class="subtitle">复制以下文案，保存以下图片，发送</div>
      <div class="text">
        <div class="pre-content">{{ meterial.content }}</div>
      </div>
      <div class="control">
        <van-button size="small" @click="handleCopyText">复制</van-button>
      </div>
    </div>
    <!-- 图片 -->
    <template v-if="existImage">
      <div class="page-guide-title">
        <van-icon name="label-o" />
        <span>Step2：保存图片</span>
      </div>
      <div class="page-guide-body images">
        <div class="image-list">
          <van-image
            v-for="(image, idx) in meterial.images"
            :key="idx"
            width="80px"
            height="80px"
            :src="image"
            @click="handleImageClick(idx, image)"
          />
        </div>
        <div class="hint">点击放大，长按保存</div>
      </div>
    </template>
    <!-- 选择客户群 -->
    <template v-if="isGroupMode">
      <div class="page-guide-title">
        <van-icon name="label-o" />
        <span>Step{{ existImage ? '3' : '2' }}：选择客户群</span>
      </div>
      <div class="page-guide-body control">
        <van-button size="small" type="primary" @click="handleSelectGroup">
          选择客户群
        </van-button>
      </div>
    </template>
    <!-- 筛选客户 -->
    <template v-else>
      <div class="page-guide-title">
        <van-icon name="label-o" />
        <span>Step{{ existImage ? '3' : '2' }}：筛选客户</span>
      </div>
      <!-- 客户-需要筛选 -->
      <div class="page-guide-body control">
        <van-button size="small" type="primary" @click="showFilter = true">
          筛选客户
        </van-button>
        <div class="label">
          已选客户数量： {{ userNum === null ? '-' : userNum }}
        </div>
      </div>
      <!-- 客户 - 底部栏 -->
      <div class="page-guide-footer">
        <van-button square @click="showHelp = true">帮助</van-button>
        <van-button
          type="primary"
          square
          :disabled="!userNum"
          :loading="building"
          @click="handleNext"
        >
          下一步
        </van-button>
      </div>
    </template>
  </div>
  <!-- 筛选客户 -->
  <van-overlay :show="showFilter" :lock-scroll="false" class="page-overlay">
    <van-nav-bar
      title="筛选客户"
      left-arrow
      left-text="返回"
      fixed
      @click="showFilter = false"
    />
    <page-filter @submit="handleSelectCustomer" />
  </van-overlay>
  <!-- 帮助 -->
  <van-overlay :show="showHelp" :lock-scroll="false" class="page-overlay">
    <van-nav-bar
      title="帮助"
      left-arrow
      left-text="返回"
      fixed
      @click="showHelp = false"
    />
    <van-image width="100%" src="/help.jpg" />
  </van-overlay>
</template>

<script>
import {
  Icon,
  Image,
  Button,
  Overlay,
  NavBar,
  Notify,
  Dialog,
  ImagePreview
} from 'vant'
import {
  getMaterialDetail,
  buildGroupLabel,
  bindGroupLabel
} from '../../../service/material.service'
import {
  WXAgentRegister,
  setClipboardData,
  openEnterpriseChat,
  shareToExternalChat,
  selectExternalContact,
  shareToExternalContact
} from '../../../utils/nwechat'
import PageFilter from './_filter.vue'

export default {
  name: 'MaterialGuide',
  components: {
    PageFilter,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [NavBar.name]: NavBar
  },
  props: {
    id: String | Number
  },
  data() {
    return {
      showFilter: false,
      showHelp: false,
      userids: null,
      building: false, // 生成标签
      binding: false, // 标签与用户绑定
      meterial: {
        title: '',
        content: '',
        images: []
      }
    }
  },
  computed: {
    isGroupMode() {
      return this.$route.query.type === 'group'
    },
    userNum() {
      if (!this.userids) return null
      return this.userids.length
    },
    existImage() {
      return this.meterial.images.length > 0
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      try {
        const { title, content, images } = await getMaterialDetail(this.id)
        this.meterial.title = title
        this.meterial.content = content
        this.meterial.images = images
      } catch (e) {
        Notify({ type: 'danger', message: '内容获取失败！' })
      }
    },
    // 复制文本
    async handleCopyText() {
      try {
        // iphone 存在 某一次复制不成功现象  errMsg: "setClipboardData:permission denied
        await WXAgentRegister()
        await setClipboardData(this.meterial.content)
        Notify({ type: 'success', message: '复制成功' })
      } catch (e) {
        console.log('复制失败error====>', e)
        Notify({
          type: 'warning',
          message: '复制失败，请重试或者自行选择进行复制。'
        })
      }
    },
    // 图片点击
    handleImageClick(idx) {
      ImagePreview({ images: this.meterial.images, startPosition: idx })
    },
    // 用户选择 一个一个的选 TODO: 需求走不通，现在不使用了
    async handleSelectUser() {
      try {
        // 选同事的接口
        // const res = await selectEnterpriseContact({
        //   fromDepartmentId: -1,
        //   mode: 'single',
        //   type: ['user']
        // })
        // 选择外部联系人
        const res = await selectExternalContact()
        const users = res.userIds
        if (users && users.length > 0) {
          await openEnterpriseChat({ externalUserIds: users.join(';') })
        }
      } catch (e) {
        // if (e.err_msg !== 'selectExternalContact:cancel') {
        //   Notify({ type: 'danger', message: '操作失败！' })
        // }
      }
    },
    // 多选客户群
    async handleSelectGroup() {
      try {
        await shareToExternalChat({
          title: '百果园店活动狂欢',
          link: location.origin + '/material/weapp',
          desc: this.meterial.content
        })
      } catch (e) {
        if (e.err_msg !== 'shareToExternalChat:cancel') {
          Notify({ type: 'danger', message: '操作失败' })
        }
      }
    },
    // 筛选的用户
    handleSelectCustomer(userids) {
      this.userids = userids
      this.showFilter = false
    },
    // 下一步
    async handleNext() {
      this.building = true
      try {
        const res = await buildGroupLabel(this.meterial.title)
        this.__tagId = res.id // 生成的标签ID临时保存起来
        Dialog.confirm({
          title: '已生成标签',
          message: `已经生成标签名为【${res.name}】的标签，${res.time}前有效，立即选择客户去发送吧！`,
          confirmButtonText: '发送',
          beforeClose: this.handleSend
        })
      } catch (e) {
        Notify({ type: 'danger', message: '标签生成失败！' })
      }
      this.building = false
    },
    // 点击发送
    async handleSend(action) {
      if (action !== 'confirm') return true

      try {
        await bindGroupLabel(this.__tagId, this.userids)
        // WXAPI 群发
        await shareToExternalContact({
          title: '百果园店活动狂欢',
          link: location.origin + '/material/weapp',
          desc: this.meterial.content
        })
        return true
      } catch (e) {
        if (e.err_msg !== 'shareToExternalContact:cancel') {
          Notify({ type: 'danger', message: '发送失败！' })
        }
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.page-guide {
  background: @white;
  padding: 0 8px 60px 8px;
  min-height: 100%;
  font-size: 14px;

  &-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: @gray-7;
    padding: 4px 0;
    margin-bottom: 6px;
    > .van-icon {
      padding-right: 4px;
    }
  }

  &-body {
    margin-bottom: 12px;
    &.hint {
      font-size: 12px;
      color: @gray-6;
    }

    &.container {
      color: @gray-7;
      > .subtitle {
        color: @gray-6;
        font-size: 12px;
        padding-bottom: 6px;
      }
      > .text {
        margin-bottom: 8px;
      }
      > .control {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    &.images {
      > .image-list > .van-image {
        margin: 6px;
      }
      > .hint {
        color: @gray-6;
        font-size: 12px;
        text-align: right;
      }
    }

    &.control {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      > .label {
        flex: 1;
      }
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    > .van-button {
      flex: 1;
    }
  }
}

.page-overlay {
  background: @white;
  height: 100%;
  padding-top: 46px;
  overflow-y: auto;
  box-sizing: border-box;
}

.page-guide-footer {
  background: #fff;
}

.pre-content {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
